import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import env from '../../env/src_config'
import { isNull, displayDate } from '../../izUtils'
import { axiosError } from '../../hoc/helpers/axiosError'
import Loader from '../../hoc/Loader/Loader'
import { useMainStore } from '../../store'
import { errorStatus } from '../../hoc/helpers/errorStatus'

const TaskList = () => {
    const { t } = useTranslation()
    const Navigate = useNavigate()
    const { headers } = useMainStore((state) => ({
        headers: state.headers,
    }))

    const [list, setList] = useState(null)

    useEffect(() => {
        function getData() {
            axios.get(env.api + '/api/qr/task/list', {headers}).then(response => {
                if (!isNull(response.data.error)) {
                    errorStatus(response.data, t);
                    Navigate(-1)
                } else if (!isNull(response.data.list)) setList(response.data.list)
            }).catch(error => {
                axiosError(error);
            });
        }

        getData()
    }, [headers]) // eslint-disable-line react-hooks/exhaustive-deps

    if (isNull(list)) {
        return <Loader />
    } else {
        return (
            <div className='task-container'>
                <div className='mobile-title'>{t('task.list.title')}</div>
                <div className='task-list'>
                    {list.map(item => (
                        <div key={"task-"+item.id} className='task-list-item' onClick={() => Navigate('/task/' + item.id)}>
                            <div className='task-list-left'>
                                <div className='task-list-ss mb-1'>
                                    {item.security_systems.map((ss, i) => (
                                        <span key={"security_systems-"+ss.id}>{i !== 0 && ', '}{ss.title}</span>
                                    ))}
                                </div>
                                <div className='task-list-client'>{item.client}</div>
                            </div>
                            <div className='task-list-right'>
                                <div className='task-list-date mb-1'>{displayDate(item.submitted_at)}</div>
                                <div className={'task-list-status ' + item.status}>{t(`status.${item.status}`)}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default TaskList