import create from 'zustand'

const lang = localStorage.getItem('AKODA.selectedLanguage');

export const useMainStore = create((set) => ({
    headers: {
        "Accept": 'application/json',
        "Accept-Language": lang,
    },
    token: null,
    setToken: (token) => set(state => (
        { 
            token,
            headers: {...state.headers, Authorization: token.length !== 0 ? 'Bearer ' + token : ''} 
        }
    )),
}))