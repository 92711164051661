import Swal from "sweetalert2";
import { isNull } from "../../izUtils";

export const errorStatus = (responseData, t) => {
    console.log(responseData)
    let errorTxt = '';
    if (!isNull(responseData.data)) {
        for (let i = 0; i < responseData.data.length; i++) {
            if (!isNull(responseData.data[i].errors)) {
                errorTxt += '<br /><b>' + responseData.data[i].title + '</b>: ' + responseData.data[i].errors[0];
            }
        }
    } else if (!isNull(responseData.error)) {
        errorTxt = responseData.error;
    } else if (!isNull(responseData.message)) {
        errorTxt = responseData.message;
    }

    Swal.fire({
        title: t('error'),
        html: errorTxt,
        icon: 'error',
        confirmButtonColor: 'var(--priamryColor)',
        confirmButtonText: t('ok')
    });
}