import React from 'react'
import { useNavigate } from 'react-router-dom'

const Header = ({logout}) => {
    const Navigate = useNavigate();

    return (
        <div className='mobile-header'>
            <button className='link-back' onClick={() => Navigate('/')}></button>
            <button className='link-logout' onClick={logout}></button>
        </div>
    )
}

export default Header