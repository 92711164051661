import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'

import env from '../../env/src_config'
import { isNull } from '../../izUtils'
import { axiosError } from '../../hoc/helpers/axiosError'
import { transformer } from '../../hoc/helpers/transformer'
import TextInput from '../../hoc/Form/TextInput'
import Button from '../../hoc/Button/Button'
import Loader from '../../hoc/Loader/Loader'
import { useMainStore } from '../../store'
import { errorStatus } from '../../hoc/helpers/errorStatus'
import ButtonLoader from '../../hoc/Loader/ButtonLoader'

const Login = ({login}) => {
    const { t } = useTranslation()
    const Navigate = useNavigate()
    const { headers } = useMainStore((state) => ({
        headers: state.headers,
    }))

    const [togglePassword, setTogglePassword] = useState(false);
    const [inputs, setInputs] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData(false);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getData = (save = false) => {
        setLoading(true);
        let payload = { save }

        if (!isNull(inputs)) {
            const keys = Object.keys(inputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(inputs[keys[i]].value) && inputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = inputs[keys[i]].value;
                } else {
                    payload.data[keys[i]] = "";
                }
            }
        }

        axios.post(env.api + '/api/login/client', payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.state) && response.data.state === 'error') {
                    errorStatus(response.data, t);
                } else if (!isNull(response.data.valid) && !response.data.valid) {
                    errorStatus(response.data, t);
                } else if (!isNull(response.data.token)) {
                    login(response.data.token)
                }
            }

            if (!isNull(response.data.data)) setInputs(transformer(response.data.data))
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            axiosError(error);
        });
    }

    const handleChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        setInputs(clonedInputs);
    }

    const onKeyUp = (e) => {
        if (e.key === 'Enter') {
            getData(true)
        }
    }

    if (isNull(inputs)) {
        return <Loader />
    } else {
        return (
            <div className='login-container'>
                <div className='mb-3'>
                    <img src={env.api + '/media/images/logo.png'} alt="Logo" style={{ maxHeight: '50px' }}/>
                </div>
                <div className='card'>
                    <div className='container'>
                        <div className='text-center mb-5'>
                            <Button title={t('login.qrcode')} onClick={() => Navigate('/qrcode')}/>
                        </div>
                        <TextInput data={inputs["email"]} onChange={handleChange} refreshAfterChange={true}/>
                        <div className="form-container">
                            <label className="form-label" htmlFor={inputs["password"].name}>{inputs["password"].title}</label>
                            <input 
                                className="form-input"
                                type={togglePassword ? "text" : "password"}
                                id={inputs["password"].name}
                                placeholder={t(`form.${inputs["password"].name}`)}
                                value={inputs["password"].value}
                                onChange={(e) => handleChange(e.target.value, inputs["password"].name)}
                                onKeyUp={onKeyUp}
                            />
                            <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span>{togglePassword ? t('hide') : t('show')}</span></div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center flex-wrap'>
                            <div className="form-checkbox-group mb-1">
                                <input type="checkbox" className="form-checkbox" checked={inputs["remember_me"].value} id="remember_me" onChange={() => handleChange(!inputs["remember_me"].value, inputs["remember_me"].name)}  />
                                <label className="checkmark" htmlFor="remember_me"></label>
                                <label className="text-muted checkbox-label" htmlFor="remember_me">{inputs["remember_me"].title}</label>
                            </div>
                            <Link to="/password-reset/get-token">{t('login.PasswordReset')}</Link>
                        </div>
                        <div className='mb-2 mt-1'>
                            {loading ?
                                <ButtonLoader />
                                :
                                <Button title={t('login.btn')} onClick={() => getData(true)}/>
                            }
                        </div>
                        <p className="haveAcc">{t('login.haveAcc')}<Link className="ms-2" to="/register">{t('login.register')}</Link></p>
                    </div>

                </div>
            </div>
        )
    }
}

export default Login