import React, {useState, useMemo} from 'react'
import { isNull } from '../../izUtils';

const MultiSelect = ({data, onChange, showErrors}) => {
    const { name, display, errors, title, values, disabled} = data

    const [value, setValue] = useState(data.value);

    const handleChange = (e, optionId) => {
        if (value.indexOf(optionId) === -1) {
            setValue(prev => [...prev, optionId])
        } else {
            setValue(prev => prev.filter(el => el !== optionId))
        }
        onChange(optionId, name);
    }

    let isError = useMemo(() => {
        return showErrors && !isNull(errors) && errors.length !== 0
    }, [errors, showErrors])

    if (display) {
        return (
            <div className="form-container">
                <label className="form-label" htmlFor={name}>{title}</label>
                {values.length !== 0 &&
                    values.map((option, index) => (
                        <div className="form-checkbox-group" key={data.name+'_'+index+'_'+option.title}>
                            <input
                                type="checkbox"
                                className="form-checkbox"
                                disabled={disabled}
                                checked={value.indexOf(option.id) !== -1}
                                id={name+'_'+option.id}
                                name={name+'_'+option.title}
                                onChange={(e) => handleChange(e, option.id)} 
                            />
                            <label className={"checkmark" + (isError ? ' error' : '')}  htmlFor={name+'_'+option.id}></label>
                            <label className="checkbox-label" htmlFor={name+'_'+option.id}>
                                {option.title 
                                    // + (!isNull(option.data) && (
                                        // (!isNull(option.data.manufacturer) ? ' - ' + option.data.manufacturer : '') +
                                        // (!isNull(option.data.system) ? ' - ' + option.data.system : '') +
                                        // (!isNull(option.data.central_station) ? ' - ' + option.data.central_station : '')
                                    // ))
                                }
                            </label>
                        </div>
                    ))
                }
            </div>
        )
    } else {
        return null;
    }
}

export default MultiSelect