import React from 'react'

const Button = (props) => {
    const { title, onClick } = props;

    return (
        <button className='btn btn-primary' onClick={onClick}>{title}</button>
    )
}

export default Button