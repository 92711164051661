import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom'
import { QrReader } from 'react-qr-reader'

import { isNull } from '../../izUtils'

const Qrcode = ({type}) => {
	const { t } = useTranslation()
	const Navigate = useNavigate()
	const [data, setData] = useState(null)
	const [facingMode, setFacingMode] = useState('environment') // user, environment

	const onResult = (result, error) => {
		if (result) {
			console.log(result?.text)
			setData(result?.text)
		}

		if (error) console.info(error)
	}

	console.log(facingMode)

	return (
		<div className='container' style={{ alignItems: 'center' }}>
			{type === 'public' ?
				<div className='mobile-header' style={{ marginBottom: '0px' }}>
					<button className='link-back' onClick={() => Navigate(-1)}></button>
					<div className='mobile-title'>{t('task.qr.title')}</div>
					<button className='switch' onClick={() => setFacingMode(facingMode === 'environment' ? 'user' : 'environment')}></button>
				</div>
				:
				<button className='switch-camera' onClick={() => setFacingMode(facingMode === 'environment' ? 'user' : 'environment')}></button>
			}
			<div className='qr-container'>
				{facingMode === 'environment' ?
					<QrReader
						key='environment'
						onResult={onResult}
						style={{ width: '100%' }}
						constraints={
							{ facingMode: 'environment' }
						}
					/>
					:
					<QrReader
						key='user'
						onResult={onResult}
						style={{ width: '100%' }}
						constraints={
							{ facingMode: 'user' }
						}
					/>
				}
			</div>
			{!isNull(data) &&
				<div className='qr-result'>
					<a href={data} target="_blank" rel="noreferrer">{data}</a>
				</div>
			}
		</div>
	);
}

export default Qrcode