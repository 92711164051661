import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import Swal from "sweetalert2";

import env from '../../env/src_config'
import { isNull } from '../../izUtils'
import { axiosError } from '../../hoc/helpers/axiosError'
import { transformer } from '../../hoc/helpers/transformer'
import { linker } from '../../hoc/helpers/linker'
import Button from '../../hoc/Button/Button'
import Loader from '../../hoc/Loader/Loader'
import { useMainStore } from '../../store'
import { errorStatus } from '../../hoc/helpers/errorStatus'

const PasswordResset = () => {
    const { t } = useTranslation()
    const Navigate = useNavigate()
    const { token } = useParams()
    const { headers } = useMainStore((state) => ({
        headers: state.headers,
    }))

    const hasToken = useRef(token !== 'get-token')
    const [inputs, setInputs] = useState(null)

    useEffect(() => {
        getData(false);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getData = (save = false) => {
        console.log("hasToken: ", hasToken.current)

        let payload = { save, platform: 'mobile' }
        if (!isNull(inputs)) {
            const keys = Object.keys(inputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(inputs[keys[i]].value) && inputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = inputs[keys[i]].value;
                }
            }
        }

        const url = env.api + '/api/reset-password' + (hasToken.current ? '/'+token : '')
        axios.post(url, payload, {headers}).then(response => {
            if (save) {
                if (!isNull(response.data.valid) && !response.data.valid) {
                    errorStatus(response.data, t)
                } else if (!isNull(response.data.message)) {
                    Swal.fire({
                        title:  t('passwordReset.success.title'),
                        text:  response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--priamryColor)',
                        confirmButtonText: t('ok')
                    }).then(value => {
                        if (value.isConfirmed && hasToken.current) Navigate('/')
                    })
                }
            }

            if (!isNull(response.data.error)) errorStatus(response.data, t)
            if (!isNull(response.data.data)) setInputs(transformer(response.data.data))
        }).catch(error => {
            axiosError(error);
        });
    }

    const handleChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        setInputs(clonedInputs);
    }

    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            textHandler: (value, id) => handleChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    if (isNull(inputs)) {
        return <Loader />
    } else {
        return (
            <div className='container'>
               <div className='mobile-header'>
                <button className='link-back' onClick={() => Navigate('/')}></button>
                    <button className='link-back' style={{ opacity: '0' }}></button>
                </div>
                <div className='mb-5' style={{ marginTop: '84px' }}>
                    <div className='mobile-title'>{t('passwordReset.title')}</div>
                    { ['email', 'password', 'password_confirmation'].map( field => mapLinker(field) ) }
                </div>
                <div>
                    <Button title={hasToken.current ? t('passwordReset.changePassword') : t('passwordReset.sendEmail')} onClick={() => getData(true)}/>
                </div>
            </div>
        )
    }
}

export default PasswordResset