import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {t} from 'i18next'
import axios from 'axios'

import env from '../../env/src_config'
import { isNull } from '../../izUtils'
import { axiosError } from '../../hoc/helpers/axiosError'
import { useMainStore } from '../../store'

const TaskVerify = ({type}) => {
    const {token} = useParams()
    const Navigate = useNavigate()
    const { headers } = useMainStore((state) => ({
        headers: state.headers,
    }))

    const [verify, setVerify] = useState('')

    useEffect(() => {
        axios.post(env.api + '/api/qr/task/verify/' + token, {}, {headers}).then(response => {
            if (!isNull(response.data.message)) {
                setVerify(response.data.message)
            } else if (!isNull(response.data.error)) {
                setVerify(response.data.error)
            }
        }).catch(error => axiosError(error));
    }, [headers, token])

    return (
        <div className='container'>
            {type === 'public' ?
				<div className='mobile-header' style={{ marginBottom: '0px' }}>
					<button className='link-back' onClick={() => Navigate(-1)}></button>
			        <div className='mobile-title'>{t('verify.titleTask')}</div>
					<button className='link-back' style={{ opacity: 0 }}></button>
				</div>
				:
                <div className='mobile-title'>{t('verify.titleTask')}</div>
			}

            <div className='text-center' style={{ marginTop: type === 'public' ? '100px' : '' }}>
                {verify}
            </div>
        </div>
    )
}

export default TaskVerify