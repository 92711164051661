import React, {useState, useEffect, useMemo} from 'react'
import { useTranslation } from "react-i18next"
import { isNull } from '../../izUtils'

const Select = ({data, onChange, showErrors}) => {
    const { name, display, errors, title, values, disabled} = data
    const { t } = useTranslation()

    const [value, setvalue] = useState("")

    useEffect(() => {
        setvalue(data.value === null ? '' : data.value)
    }, [data.value])

    const handleChange = (e) => {
        setvalue(e.target.value)
        onChange(e.target.value, name)
    };

    let isError = useMemo(() => {
        return showErrors && !isNull(errors) && errors.length !== 0
    }, [errors, showErrors])
    
    if (display) {
        return (
            <div className="form-container">
                <label className="form-label" htmlFor={name}>{title}</label>
                <select className={"form-input" + (isError ? ' error' : '')} id={name} value={value} onChange={handleChange} disabled={disabled}>
                    <option value=''>{t('pleaseSelect')}</option>
                    {values.map((option, index) => (
                        <option key={index} value={option.id}>{option.title}</option>
                    ))}
                </select>
            </div>
        )
    } else {
        return null
    }
}

export default Select