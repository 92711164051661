import { Fragment, Suspense, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from 'react-cookie';
import Swal from "sweetalert2";

import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import Verify from "./components/Register/Verify";
import PasswordResset from "./components/Login/PasswordResset";
import Home from "./components/Home/Home";
import TaskCreate from "./components/Tasks/TaskCreate";
import TaskCreatePublic from "./components/Tasks/TaskCreatePublic";
import TaskList from "./components/Tasks/TaskList";
import TaskSingle from "./components/Tasks/TaskSingle";
import TaskVerify from "./components/Tasks/TaskVerify";
import AddFacility from "./components/Facility/AddFacility";
import FacilityList from "./components/Facility/FacilityList";
import Qrcode from "./components/Qrcode/Qrcode";
import Header from "./components/Header/Header";
import Loader from "./hoc/Loader/Loader";
import { useMainStore } from "./store";
import { isNull, parseJwt } from "./izUtils";
import env from "./env/src_config";

import './assets/css/main.css'

function App() {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [cookies, setCookie, removeCookie] = useCookies(['token'])
    const { token, setToken } = useMainStore((state) => ({
        token: state.token,
        setToken: state.setToken
    }))

    useEffect(() => {
        // Language
        const lang = localStorage.getItem('AKODA.selectedLanguage')
        if (isNull(lang)) localStorage.setItem('AKODA.selectedLanguage', 'sl-SI')
        
        // Token
        let kuki = cookies;
        if (!isNull(kuki) && !isNull(kuki.token)) {
            const parsedToken = parseJwt(kuki.token);
            if (parsedToken.exp * 1000 < Date.now()) {
                Swal.fire({
                    title: t('logout.title'),
                    html: t('logout.text'),
                    icon: 'error',
                    confirmButtonColor: 'var(--priamryColor)',
                    confirmButtonText: t('ok')
                });
                logout();
            } else {
                setToken(kuki.token)
            }
        } else {
            setToken('')
        }
    }, [cookies]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        document.title = env.organization_name + " - mobile app";
        const root = document.documentElement;
        root?.style.setProperty("--priamryColor", env?.colors?.primary_color);
        root?.style.setProperty("--priamryColorHover", env?.colors?.primary_color_hover);

    }, [])

    useEffect(() => {
        let link = document.querySelector("link[rel*='icon']");

        if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.head.appendChild(link);
        }

        link.href = `/favicon/${env?.organization_name.toLowerCase().replace(' ', '')}.ico`;

    }, [])

    const login = (token) => {
        setCookie('token', token, { path: '/' })
    }

    const logout = () => {
        removeCookie('token', { path: '/' })
        navigate('/')
    }

    if (isNull(token)) {
        return <Loader />
    } else {
        return (
            <div className="wrap">
                {token.length === 0 ?
                    <Routes>
                        <Route path="/" element={ <Suspense fallback={<Loader />}><Login login={login}/></Suspense> } />
                        <Route path="/register" element={ <Suspense fallback={<Loader />}><Register /></Suspense> } />
                        <Route path="/password-reset/:token" element={ <Suspense fallback={<Loader />}><PasswordResset /></Suspense> } />
                        <Route path="/email/verify/:token" element={ <Suspense fallback={<Loader />}><Verify /></Suspense> } />
                        <Route path="/qrcode" element={ <Suspense fallback={<Loader />}><Qrcode type="public"/></Suspense> } />
                        <Route path="/task-create/:client/:facility" element={ <Suspense fallback={<Loader />}><TaskCreatePublic /></Suspense> } />
                        <Route path="/task/verify/:token" element={ <Suspense fallback={<Loader />}><TaskVerify type="public"/></Suspense> } />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                    :
                    <Fragment>
                        <Header logout={logout} />
                        <div style={{ marginTop: '84px' }}>
                            <Routes>
                                <Route path="/" element={ <Suspense fallback={<Loader />}><Home /></Suspense> } />
                                <Route path="/qrcode" element={ <Suspense fallback={<Loader />}><Qrcode type="private"/></Suspense> } />
                                <Route path="/task-create" element={ <Suspense fallback={<Loader />}><TaskCreate /></Suspense> } />
                                <Route path="/task-create/:client/:facility" element={ <Suspense fallback={<Loader />}><TaskCreatePublic /></Suspense> } />
                                <Route path="/tasks" element={ <Suspense fallback={<Loader />}><TaskList /></Suspense> } />
                                <Route path="/task/:id" element={ <Suspense fallback={<Loader />}><TaskSingle /></Suspense> } />
                                <Route path="/task/verify/:token" element={ <Suspense fallback={<Loader />}><TaskVerify type="private"/></Suspense> } />
                                <Route path="/add-facility" element={ <Suspense fallback={<Loader />}><AddFacility type="private"/></Suspense> } />
                                <Route path="/facility-list" element={ <Suspense fallback={<Loader />}><FacilityList type="private"/></Suspense> } />
                                <Route path="*" element={<Navigate to="/" />} />
                            </Routes>
                        </div>
                    </Fragment>
                }
            </div>
        );
    }
}

export default App;
