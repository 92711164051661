import { isNull } from "../../izUtils";

export const transformer = (fields) => {
    if (!isNull(fields)) {
        let data = {};
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].type === "multiselect" && fields[i].value === '') {
                fields[i].value = [];
                data[fields[i].name] = fields[i];
            } else {
                data[fields[i].name] = fields[i];
            }
        }
    
        return data;
    }
}
