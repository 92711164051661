import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

import env from '../../env/src_config'
import { isNull } from '../../izUtils'
import { axiosError } from '../../hoc/helpers/axiosError'
import Loader from '../../hoc/Loader/Loader'
import { useMainStore } from '../../store'
import { errorStatus } from '../../hoc/helpers/errorStatus'
import TextDisplay from '../../hoc/Form/TextDisplay'
import FileUpload from '../../hoc/FileUpload/FileUpload'
    
const TaskSingle = () => {
    const { t } = useTranslation()
    const Navigate = useNavigate()
    const { id } = useParams()
    const { headers } = useMainStore((state) => ({
        headers: state.headers,
    }))

    const [data, setData] = useState(null)

    const lsTaskFiles = 'AKODA-mobile.taskFiles';

    useEffect(() => {
        localStorage.removeItem(lsTaskFiles)
        function getData() {
            axios.get(env.api + '/api/qr/task/get/' + id, {headers}).then(response => {
                if (!isNull(response.data.error)) {
                    errorStatus(response.data, t);
                    Navigate(-1)
                } else if (!isNull(response.data.data)) {
                    setData(response.data.data)
                }
            }).catch(error => {
                axiosError(error);
            });
        }

        getData()
    }, [headers, id]) // eslint-disable-line react-hooks/exhaustive-deps

    // Create fields
    const mapLinker = (field) => {
        let value = data[field.name]
        if (field.location) {
            const splitLocation = field.location.split('.')
            value=data[splitLocation[0]][splitLocation[1]]
        }

        const params = { title: t('form.task.'+field.name), value, type: field.type }
        return <TextDisplay key={"taskField-"+field.name} data={params} />
    }

    if (isNull(data)) {
        return <Loader />
    } else {
        let fields = [
            {name: "client", location: "client.client", type: 'text'},
            {name: "location", location: "client.location", type: 'text'},
            {name: "invoice_recipient", location: "client.invoice_recipient", type: 'text'},
            {name: "security_systems", type: 'multiSelect'},
            {name: "client_notes", type: 'area'},
            {name: "submitted_at", type: 'datetime'},
            {name: "planned_execution_date", type: 'date'},
        ].map(field => mapLinker(field))

        return (
            <div className='container'>
                <div className='mobile-title'>{t('task.single.title')}</div>
                <div className='me-1 ms-1'>
                    {fields}
                    <FileUpload data={{display: true, disabled: true, values: data.client_files}} saveToLS={lsTaskFiles} />
                </div>
            </div>
        )
    }
}

export default TaskSingle