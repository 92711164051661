import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { isNull } from './izUtils'

let lng = 'sl-SI';
const new_lang = localStorage.getItem('AKODA.selectedLanguage');
if (!isNull(new_lang)) lng = new_lang;

const translation = require(`./assets/i18n/${lng}.json`)
let resources = {};
resources[lng] = { translation }

i18n
  .use(initReactI18next)
  .init({
        resources,
        lng: lng,
        fallbackLng: "sl-SI",
        interpolation: {
            escapeValue: false
        }
    });