export const isNull = (value) => {
    if (value === undefined || value === null) return true;
    return false;
};

export const parseJwt = (token) => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const displayDate = (date, type, withSeconds) => {
    const newDate = new Date(date);

    const year = newDate.getFullYear()+'';
    let month = newDate.getMonth() + 1+'';
    let day = newDate.getDate()+'';
    let hours = newDate.getHours()+'';
    let minutes = newDate.getMinutes()+'';
    let seconds = newDate.getSeconds()+''

    if (month.length === 1) month = '0' + month;
    if (day.length === 1) day = '0' + day;
    if (hours.length === 1) hours = '0' + hours;
    if (minutes.length === 1) minutes = '0' + minutes;
    if (seconds.length === 1) seconds = '0' + seconds;


    if (type === 'date') {
        return day + "." + month + "." + year;
    } else if (type === 'time') {
        return hours + ":" + minutes + (withSeconds ? (":" + seconds) : "");
    } else {
        return day + "." + month + "." + year + " " + hours + ":" + minutes + (withSeconds ? (":" + seconds) : "");
    }
}

export const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'kb', 'mb', 'gb', 'tb'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}