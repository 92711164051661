import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Swal from "sweetalert2";

import env from '../../env/src_config'
import { isNull } from '../../izUtils'
import { axiosError } from '../../hoc/helpers/axiosError'
import { transformer } from '../../hoc/helpers/transformer'
import { linker } from '../../hoc/helpers/linker'
import Button from '../../hoc/Button/Button'
import Loader from '../../hoc/Loader/Loader'
import { useMainStore } from '../../store'
import { errorStatus } from '../../hoc/helpers/errorStatus'

const AddFacility = () => {
    const { t } = useTranslation()
    const Navigate = useNavigate()
    const { headers } = useMainStore((state) => ({
        headers: state.headers,
    }))

    const [inputs, setInputs] = useState(null)
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        getData(false, null);
    }, [headers]) // eslint-disable-line react-hooks/exhaustive-deps

    const getData = (save, passedInputs) => {
        let payload = { save };

        if (!isNull(passedInputs)) {
            const keys = Object.keys(passedInputs);
            payload.data = {};
            for (let i = 0; i < keys.length; i++) {
                if (!isNull(passedInputs[keys[i]].value) && passedInputs[keys[i]].value.length !== 0) {
                    payload.data[keys[i]] = passedInputs[keys[i]].value;
                }
            }
        }

        axios.post(env.api + '/api/qr/request', payload, {headers}).then(response => {
            if (save) {
                if (response.data.state === 'success') {
                    Swal.fire({
                        title:  t('task.create.success.title'),
                        text:  response.data.message,
                        icon: 'success',
                        confirmButtonColor: 'var(--priamryColor)',
                        confirmButtonText: t('ok')
                    }).then(() => Navigate('/'))
                } else {
                    errorStatus(response.data, t);
                    setShowErrors(true);
                }
            } else {
                if (!isNull(response.data.error)) {
                    errorStatus(response.data, t);
                }
            }

            if (!isNull(response.data.data)) setInputs(transformer(response.data.data))
        }).catch(error => {
            axiosError(error);
        });
    }

    const handleChange = (value, name) => {
        let clonedInputs = {...inputs}
        clonedInputs[name].value = value;
        getData(false, clonedInputs);
    }


    // Create fields
    const mapLinker = (field) => {
        const inputlinkerFields = {
            field,
            inputs,
            showErrors,
            textHandler: (value, id) => handleChange(value, id),
        }

        return linker(inputlinkerFields);
    }

    if (isNull(inputs)) {
        return <Loader />
    } else {
        return (
            <div className='container'>
                <div className='me-1 ms-1'>
                    <div className='mobile-title'>{t('facility.add.header')}</div>
                    { ['request'].map(field => mapLinker(field)) }
                    <div className='mt-5 mb-3'>
                        <Button title={t('facility.add.btn')} onClick={() => getData(true, inputs)}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default AddFacility