import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import env from '../../env/src_config'
import { isNull, displayDate } from '../../izUtils'
import { axiosError } from '../../hoc/helpers/axiosError'
import Loader from '../../hoc/Loader/Loader'
import { useMainStore } from '../../store'
import { errorStatus } from '../../hoc/helpers/errorStatus'

const FacilityList = () => {
    const { t } = useTranslation()
    const Navigate = useNavigate()
    const { headers } = useMainStore((state) => ({
        headers: state.headers,
    }))
    
    const [list, setList] = useState(null)

    useEffect(() => {
        function getData() {
            axios.post(env.api + '/api/qr/request/list', {}, {headers}).then(response => {
                if (!isNull(response.data.error)) {
                    errorStatus(response.data, t);
                    Navigate(-1)
                } else if (!isNull(response.data.data)) setList(response.data.data)
            }).catch(error => {
                axiosError(error);
            });
        }

        getData()
    }, [headers]) // eslint-disable-line react-hooks/exhaustive-deps
    
    if (isNull(list)) {
        return <Loader />
    } else {
        return (
            <div className='task-container'>
                <div className='mobile-title'>{t('facility.list.title')}</div>
                <div className='task-list'>
                    {list.map(item => (
                        <div key={"facility-"+item.id} className='task-list-item'>
                            <div className='task-list-left'>
                                <div className='task-list-client'>{item.message}</div>
                            </div>
                            <div className='task-list-right'>
                                <div className='task-list-date mb-1'>{displayDate(item.created_at)}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default FacilityList