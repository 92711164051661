import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import env from '../../env/src_config'
import { isNull } from '../../izUtils'
import { axiosError } from '../../hoc/helpers/axiosError'
import Button from '../../hoc/Button/Button'
import Loader from '../../hoc/Loader/Loader'
import { useMainStore } from '../../store'

const Home = () => {
    const { t } = useTranslation()
    const Navigate = useNavigate()
    const { headers } = useMainStore((state) => ({
        headers: state.headers,
    }))

    const [clients, setClients] = useState(null)
    const [name, setName] = useState(null)

    useEffect(() => {
        function getData() {
            axios.get(env.api + '/api/qr/index', {headers}).then(response => {
                if (!isNull(response.data.clients)) setClients(response.data.clients)
                if (!isNull(response.data.name)) setName(response.data.name)
            }).catch(error => {
                axiosError(error);
            });
        }

        getData()
    }, [headers])

    if (isNull(clients)) {
        return <Loader />
    } else {
        return (
            <div className='card'>
                <div className='container text-center'>
                    <div className='text-center'>
                        <div className='home-name'>{name}</div>
                        <div className='home-areas'>{t('home.areas')}</div>
                    </div>
                    <div className='home-clients'>
                        {clients.map(client => (
                            <div key={"client-"+client.id}>
                                <div>
                                    {client.client}
                                </div>
                                <ul className='mb-1'>
                                    {client.facilities.map(facility => (
                                        <li key={"facility-"+facility.id}>{facility.name}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                    <div className='mb-2'>
                        <Button title={t('home.qrcode')} onClick={() => Navigate('/qrcode')}/>
                    </div>
                    <div className='mb-2'>
                        <Button title={t('home.report')} onClick={() => Navigate('/task-create')}/>
                    </div>
                    <div className='mb-5'>
                        <Button title={t('home.check')} onClick={() => Navigate('/tasks')}/>
                    </div>
                    <div className='mb-2'>
                        <Button title={t('home.addFacility')} onClick={() => Navigate('/add-facility')}/>
                    </div>
                    <div className='mb-2'>
                        <Button title={t('home.facilityList')} onClick={() => Navigate('/facility-list')}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home