import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import axios from 'axios'

import env from '../../env/src_config'
import { isNull } from '../../izUtils'
import { axiosError } from '../../hoc/helpers/axiosError'
import { useMainStore } from '../../store'

const Verify = () => {
    const {token} = useParams()
    const { t } = useTranslation()
    const Navigate = useNavigate()
    const { headers } = useMainStore((state) => ({
        headers: state.headers,
    }))

    const [verify, setVerify] = useState('')

    useEffect(() => {
        axios.post(env.api + '/api/email/verify/' + token, {}, {headers}).then(response => {
            if (!isNull(response.data.message)) {
                setVerify(response.data.message)
            } else if (!isNull(response.data.error)) {
                setVerify(response.data.error)
            }
        }).catch(error => axiosError(error));
    }, [headers, token])

    return (
        <div className='container'>
            <div className='mobile-header'>
                <button className='link-back' onClick={() => Navigate('/login')}></button>
                <div className='mobile-title'>{t('verify.titleEmail')}</div>
                <button className='link-back' style={{ opacity: '0' }}></button>
            </div>
            <div style={{ marginTop: '84px' }}>
                <div className='text-center mt-2'>{verify}</div>
            </div>
        </div>
    )
}

export default Verify