import TextInput from '../Form/TextInput';
import TextArea from '../Form/TextArea';
import Select from '../Form/Select';
import MultiSelect from '../Form/MultiSelect';
import Checkbox from '../Form/Checkbox';

export const linker = ({field, inputs, showErrors, refreshAfterChange, lsName, textHandler, selectHandler, dateHandler, booleanHandler, multiHandler}) => {
    if (inputs[field]) {
        switch (inputs[field].type) {
            case 'text':
                return <TextInput key={'input-'+inputs[field].name} data={inputs[field]} onChange={textHandler} onBlur={textHandler} refreshAfterChange={refreshAfterChange} showErrors={showErrors} lsName={lsName} />
            case 'password':
                return <TextInput key={'input-'+inputs[field].name} data={inputs[field]} onChange={textHandler} onBlur={textHandler} refreshAfterChange={refreshAfterChange} showErrors={showErrors} lsName={lsName} />
            case 'textarea':
                return <TextArea key={'input-'+inputs[field].name} data={inputs[field]} onChange={textHandler} onBlur={textHandler} refreshAfterChange={refreshAfterChange} showErrors={showErrors} lsName={lsName} />
            case 'select':
                return <Select key={'input-'+inputs[field].name} data={inputs[field]} onChange={selectHandler} showErrors={showErrors} lsName={lsName} />
            case 'multiselect':
                return <MultiSelect key={'input-'+inputs[field].name} data={inputs[field]} onChange={multiHandler} showErrors={showErrors} lsName={lsName} />
            case 'checkbox':
                return <Checkbox key={'input-'+inputs[field].name} data={inputs[field]} onChange={booleanHandler} showErrors={showErrors} lsName={lsName} />
            default:
                return null;
        }
    }
}
