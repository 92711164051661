import React, { useState, useEffect, useMemo, Fragment } from 'react'
import { t } from 'i18next';
import {useDropzone} from 'react-dropzone'
import axios from 'axios';

import { useMainStore } from '../../store';
import env from '../../env/src_config';
import { axiosError } from '../helpers/axiosError';
import { errorStatus } from '../helpers/errorStatus';
import { getFilePreview } from '../helpers/getFilePreview';
import { isNull, bytesToSize } from '../../izUtils';

const FileUpload = ({data, saveToLS}) => {
    const { headers } = useMainStore((state) => ({
        headers: state.headers,
    }))

    const [files, setFiles] = useState([])
    const [loadingAdd, setLoadingAdd] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(null)

    useEffect(() => {
        if (!isNull(data.values) && data.values.length !== 0) {
            setLoadingAdd(true);
            Promise.all(
                data.values.map(async (file) => {
                    const splited = file.title.split('.')
                    file.ext = splited[splited.length - 1]
                    if (['png', 'jpg', 'jpeg'].indexOf(file.ext) !== -1) {
                        const getFile = await getFilePreview(file, headers);
                        file.preview =  URL.createObjectURL(getFile)
                    }

                    return file;
                })
            ).then(data => {
                setFiles(data)
                localStorage.setItem(saveToLS, JSON.stringify(data));
                setLoadingAdd(false);
            });
        }

        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const uploadFile = (file) => {
        setLoadingAdd(true);
        const formData = new FormData();
        formData.append('file', file);
        let url = '/api/public/file/create'
        if (!isNull(headers.Authorization) && headers.Authorization !== '') url = '/api/private/file/create'

        axios.post(env.api + url, formData, {headers}).then(response => {
            if (!isNull(response.data.id)) {
                let lsFiles = JSON.parse(localStorage.getItem(saveToLS));
                let responseData = {...response.data};
                responseData.type = response.data.mime;
                responseData.preview =  URL.createObjectURL(file)

                if (isNull(lsFiles)) {
                    lsFiles = [responseData];
                } else {
                    lsFiles.push(responseData)
                }

                setFiles(lsFiles)
                localStorage.setItem(saveToLS, JSON.stringify(lsFiles));
            } else {
                errorStatus(response.data, t);
            }
            setLoadingAdd(false);
        }).catch(error => {
            setLoadingAdd(false);
            axiosError(error);
        });
    }

    const { getRootProps, getInputProps } = useDropzone({ 
        onDrop: acceptedFiles => {
            acceptedFiles.forEach(file => uploadFile(file))
        }
    })

    const handleFile = (file, type) => {
        let url = '/api/public/file/'
        if (!isNull(headers.Authorization) && headers.Authorization !== '') url = '/api/private/file/'

        if (type === 'get') {
            axios.get(env.api + url + file.id, {headers, responseType: 'blob'}).then(response => {
                const href = window.URL.createObjectURL(response.data);
                const a = document.createElement('a');
                a.download = file.title;
                a.href = href;
                a.click();
                a.href = '';
            }).catch(error => {
                axiosError(error);
            });
        } else if (type === 'delete') {
            if (!data.disabled) {
                setLoadingDelete(file.id);
                if (!isNull(headers.Authorization) && headers.Authorization !== '') {
                    axios.delete(env.api + '/api/private/file/' + file.id, {headers}).then(response => {
                        if (!isNull(response.data.state) && response.data.state === "success") {
                            let lsFiles = JSON.parse(localStorage.getItem(saveToLS));
                            if (!isNull(lsFiles)) lsFiles = lsFiles.filter(f => f.id !== file.id )
                            setFiles(lsFiles)
                            localStorage.setItem(saveToLS, JSON.stringify(lsFiles));
                        } else {
                            errorStatus(response.data, t);
                        }
                        setLoadingDelete(null);
                    }).catch(error => {
                        setLoadingDelete(null);
                        axiosError(error);
                    });
                } else {
                    let lsFiles = JSON.parse(localStorage.getItem(saveToLS));
                    if (!isNull(lsFiles)) lsFiles = lsFiles.filter(f => f.id !== file.id )
                    setFiles(lsFiles)
                }
            }
        }
    }

    const displayFiles = useMemo(() => {
        return files.map(file => (
            <li key={file.id} className="file-box mb-1 me-1 ms-0">
                <div className="file-top">
                    {['png', 'jpg', 'jpeg'].indexOf(file.ext) !== -1 ?
                        <img src={file.preview}
                            alt={file.title}
                            className="task-image"
                            onClick={() => handleFile(file, 'get')}
                            onLoad={() => { URL.revokeObjectURL(file.preview) }} // Revoke data uri after image is loaded
                        />
                        :
                        <i className={`fa fa-file-${file.ext}-o txt-primary`} onClick={() => handleFile(file, 'get')}></i>
                    }
                </div>
                <div className="file-bottom">
                    <h6 onClick={() => handleFile(file, 'get')}>{file.title}</h6>
                    <div className='d-flex flex-wrap justify-content-between align-items-end'>
                        <div className="mb-0 mt-1">{bytesToSize(file.size)}</div>
                        {!data.disabled && <div style={{ cursor: 'pointer', fontWeight: '500' }} onClick={() => handleFile(file, 'delete')}>X</div>}
                    </div>
                </div>
                {(!isNull(loadingDelete) && (loadingDelete === file.id)) &&
                    <div style={{ position: 'absolute', inset: '0', backgroundColor: '#00000030' }}>
                        <div className='d-flex justify-content-center'>
                            <span className="loader-box mt-5">
                                <div className="loader-19"></div>
                            </span>
                        </div>
                    </div>
                }
            </li>
        ));
    }, [files, data.disabled, loadingDelete]) // eslint-disable-line react-hooks/exhaustive-deps

    const style = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#00aeef',
        borderStyle: 'dashed',
        backgroundColor: '#00aeef20',
        color: '#000',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    }

    if (data.display) {
        return (
            <Fragment>
                {!data.disabled && <label className="form-label">{data.title}</label>}
                <div className='file-content'>
                    <div className='file-manager '>
                        <div className='files'>
                            <div>
                                {!data.disabled &&
                                    <div sm="12">
                                        <div {...getRootProps({style})}>
                                            <input {...getInputProps()} />
                                            <p className='text-center'>{t('dragndrop')}</p>
                                        </div>
                                    </div>
                                }
                                <div sm="12">
                                    <div className='d-flex flex-wrap mt-2'>
                                        {displayFiles}
                                        {loadingAdd &&
                                            <span className="loader-box ms-5">
                                                <div className="loader-19"></div>
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    } else {
        return null;
    }
}

export default FileUpload