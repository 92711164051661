import React, {useState, useEffect, useMemo} from 'react';
import { t } from 'i18next';
import { isNull } from '../../izUtils';

const TextArea = ({data, onChange, onBlur, showErrors}) => {
    const { type, name, display, errors, title, disabled} = data

    const [value, setValue] = useState("");
    const maxCount = 225;

    useEffect(() => {
        setValue(data.value === null ? '' : data.value);
    }, [data.value]);

    const handleChange = (e) => {
        if (e.target.value.length <= maxCount) {
            setValue(e.target.value)
        }
    };

    let isError = useMemo(() => {
        return showErrors && !isNull(errors) && errors.length !== 0
    }, [errors, showErrors])

    if (display) {
        return (
            <div className="form-container">
                <label className="form-label" htmlFor={name}>{title}</label>
                <textarea className={"form-input" + (isError ? ' error' : '')}
                    style={{ height: '200px', resize: 'none' }}
                    disabled={disabled}
                    type={type}
                    id={name}
                    value={value}
                    onChange={handleChange}
                    onBlur={() => isNull(onBlur) ? {} : onBlur(value, name)}
                />
                <div className='text-area-note'>
                    <small>{t(`form.area.${name}`)}</small>
                    <small>{value.length}/{maxCount}</small>
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default TextArea